import React, { ReactElement } from 'react';
import get from 'lodash/get';
import { FileType, Brick } from 'components/bricks/types/brick.type';
import { MODEL_CREATIVES } from 'components/bricks/constants';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import { CreativeV2Media } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import RestrictionAlert from './alert';
import { BricksMediaInput } from './bricks-media-input';
import CreativeInputHelper from '../helpers/creative-input-helper';
import AlertTitle from 'components/ui-components-v2/AlertTitle';
import Alert from 'components/ui-components-v2/Alert';
import '../styles/main.scss';

interface ComponentStoreProps {
    isLoading?: boolean;
}
interface Props {
    brick: Brick;
    children?: ReactElement;
    assetType?: FileType;
    showAssetGalleryDialog?: boolean;
    showComments?: boolean;
    setShowAssetGalleryDialog?: (show: boolean) => void;
}

const BricksCreativeInput = ({ assetType, brick, showAssetGalleryDialog, showComments, setShowAssetGalleryDialog }: Props) => {
    const { isLoading } = useComponentStore<ComponentStoreProps>('Bricks', { fields: { isLoading: 'assetUpload.isLoading' } });
    const creatives: CreativeV2Media[] | undefined = get(brick, MODEL_CREATIVES);
    const creative = creatives && creatives?.[0];

    return (
        <div className="bricks-creative-input">
            {brick && CreativeInputHelper.hasRestrictions(brick) && (
                <div className="bricks-creative-input__alert">
                    {brick?.data?.briefing?.description && (
                        <Alert
                            variant="outlined"
                            severity={'info'} >
                            <AlertTitle>Description</AlertTitle>
                            {brick?.data?.briefing?.description}
                        </Alert>
                    )}
                    {CreativeInputHelper.hasRestrictions(brick) && (
                        <RestrictionAlert brick={brick} />
                    )}
                </div>
            )
            }
            <div className="bricks-creative-input__media">
                <BricksMediaInput
                    isLoading={isLoading}
                    preview={creative?.data?.url}
                    value={creative}
                    fileType={CreativeInputHelper.getAssetType(brick, assetType)}
                    onHandleAssetMutation={(assetData, dataType) => CreativeInputHelper.handleAssetMutation(brick, assetData, dataType)}
                    showAssetGalleryDialog={showAssetGalleryDialog}
                    showComments={showComments}
                    setShowAssetGalleryDialog={setShowAssetGalleryDialog}
                />
            </div>
        </div >
    );
};

export default BricksCreativeInput;
