import React, { useState } from 'react';
import AlertTitle from 'components/ui-components-v2/AlertTitle';
import Alert from 'components/ui-components-v2/Alert';
import Button from 'components/ui-components-v2/Button';
import { Brick } from 'components/bricks/types/brick.type';
import Translation from 'components/data/Translation';
import RestrictionAlertDialog from './alert-dialog';
import { AssetRestrictions, AssetRestrictionsKeys } from '../../../../../types/preset';
import { getSeverity } from '../helpers/asset.helpers';
import { restrictionDataMap } from '../data/restriction-data-map';
import { getFileExtensionName } from '../../presets-dialog/utils/table-utils';
import { convertMaxFileSizeToHumanReadable } from '../helpers/asset.helpers';

interface Props {
    brick: Brick;
}

const RestrictionAlert = ({ brick }: Props) => {
    const [showDialog, setShowDialog] = useState(false);

    const severity = getSeverity(brick);

    const getTitle = () => {
        const presetTitle = brick.data?.presets?.[0]?.title;
        return presetTitle ? presetTitle : Translation.get('fileRestrictions', 'bricks');
    };

    /**
     * Generates a formatted string description of asset restrictions
     * @returns A string containing all restrictions formatted and joined with ' | ', or empty string if no restrictions
     */
    const getDescription = (): string => {
        const restrictions: AssetRestrictions | undefined = brick?.data?.presets?.[0]?.restrictions;
        if (!restrictions) return '';

        // Formats restriction values based on their type
        const formatRestrictionValue = (restriction: string, value: any): string => {
            switch (restriction) {
                case 'fileExtension':
                    return getFileExtensionName(value);
                case 'maxSizeKb':
                    return convertMaxFileSizeToHumanReadable(value);
                default:
                    return value;
            }
        };

        return (
            (Object.keys(restrictions) as AssetRestrictionsKeys[])
                // Filter out aspectRatioPercentageTolerance from the restrictions
                .filter((restriction) => restriction !== 'aspectRatioPercentageTolerance')
                .filter((restriction) => restrictionDataMap[restriction])
                .map((restriction) => {
                    const { message, unit } = restrictionDataMap[restriction];
                    const value = formatRestrictionValue(restriction, restrictions[restriction]);
                    return `${message}: ${value}${unit ?? ''}`;
                })
                .join(' | ')
        );
    };

    return (
        <>
            <Alert
                severity={severity}
                action={
                    <Button color="inherit" size="small" style={{ minWidth: 100 }} onClick={() => setShowDialog(true)}>
                        <b>{Translation.get('actions.moreDetails', 'common')}</b>
                    </Button>
                }>
                <AlertTitle>{getTitle()}</AlertTitle>
                {getDescription()}
            </Alert>

            {showDialog && <RestrictionAlertDialog brick={brick} severity={severity} onClose={() => setShowDialog(false)} />}
        </>
    );
};

export default RestrictionAlert;
