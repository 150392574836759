import React from 'react';
import { CreativeV2Media } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import VideoPlayerComments from 'components/ui-components-cape/VideoPlayerComments';
import AudioPlayerComments from 'components/ui-components-cape/AudioPlayerComments';
import ImageComments from 'components/ui-components-cape/ImageComments';

interface Props {
    creative: CreativeV2Media;
}

const MediaPreviewComments = ({ creative }: Props) => {
    const fileType = creative?.data.fileType;
    if (fileType === 'video') return <VideoPlayerComments url={creative.data.url} />;
    if (fileType === 'audio') return <AudioPlayerComments url={creative.data.url} />;
    if (fileType === 'image') return <ImageComments url={creative.data.url} />;
    return null;
};

export default MediaPreviewComments;
