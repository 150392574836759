
      import API from "!../../../../../../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
      import domAPI from "!../../../../../../../../node_modules/style-loader/dist/runtime/styleDomAPI.js";
      import insertFn from "!../../../../../../../../node_modules/style-loader/dist/runtime/insertBySelector.js";
      import setAttributes from "!../../../../../../../../node_modules/style-loader/dist/runtime/setAttributesWithoutAttributes.js";
      import insertStyleElement from "!../../../../../../../../node_modules/style-loader/dist/runtime/insertStyleElement.js";
      import styleTagTransformFn from "!../../../../../../../../node_modules/style-loader/dist/runtime/styleTagTransform.js";
      import content, * as namedExport from "!!../../../../../../../../node_modules/css-loader/dist/cjs.js!../../../../../../../../node_modules/sass-loader/dist/cjs.js!../../../../../../../../node_modules/sass-resources-loader/lib/loader.js??ruleSet[1].rules[2].use[3]!./bricks-media-input.scss";
      
      

var options = {};

options.styleTagTransform = styleTagTransformFn;
options.setAttributes = setAttributes;
options.insert = insertFn.bind(null, "head");
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

var update = API(content, options);



export * from "!!../../../../../../../../node_modules/css-loader/dist/cjs.js!../../../../../../../../node_modules/sass-loader/dist/cjs.js!../../../../../../../../node_modules/sass-resources-loader/lib/loader.js??ruleSet[1].rules[2].use[3]!./bricks-media-input.scss";
       export default content && content.locals ? content.locals : undefined;
