import { AssetMetadata } from 'components/bricks/helpers/asset.helpers';
import { AssetRestrictionsKeys } from 'components/bricks/types/preset';
import { AssetValidationResult } from '../components/alert-dialog';

type AssetMetadataRestrictionKeys = keyof Omit<AssetMetadata, 'warnings' | 'errors'>;

interface RestrictionDataMapValue {
    message: string;
    assetMetadataKey?: AssetMetadataRestrictionKeys;
    unit?: AssetValidationResult['unit'];
}

/**
 * Map of restriction types with their corresponding message and asset metadata key.
 * The asset metadata key and the message is used to get the value from the asset metadata to display in the preset info dialog.
 */
export const restrictionDataMap: Record<AssetRestrictionsKeys, RestrictionDataMapValue> = {
    width: { message: 'Width', assetMetadataKey: 'width', unit: 'px' },
    height: { message: 'Height', assetMetadataKey: 'height', unit: 'px' },
    fileExtension: { message: 'File extension', assetMetadataKey: 'fileExtension' },
    minWidth: { message: 'Minimum width', assetMetadataKey: 'width', unit: 'px' },
    minHeight: { message: 'Minimum height', assetMetadataKey: 'height', unit: 'px' },
    maxWidth: { message: 'Maximum width', assetMetadataKey: 'width', unit: 'px' },
    maxHeight: { message: 'Maximum height', assetMetadataKey: 'height', unit: 'px' },
    maxSizeKb: { message: 'Max size', assetMetadataKey: 'humanFileSize' },
    minAspectRatio: { message: 'Minimum aspect ratio', assetMetadataKey: 'aspectRatio' },
    maxAspectRatio: { message: 'Maximum aspect ratio', assetMetadataKey: 'aspectRatio' },
    minVideoDuration: { message: 'Minimum video duration', assetMetadataKey: 'duration', unit: 'sec' },
    maxVideoDuration: { message: 'Maximum video duration', assetMetadataKey: 'duration', unit: 'sec' },
    aspectRatioPercentageTolerance: { message: 'Aspect ratio percentage tolerance', assetMetadataKey: undefined },
    minDuration: { message: 'Minimum duration', assetMetadataKey: 'duration', unit: 'sec' },
    maxDuration: { message: 'Maximum duration', assetMetadataKey: 'duration', unit: 'sec' },
    mimeType: { message: 'Mime type', assetMetadataKey: 'fileMime' },
    bitRate: { message: 'Bitrate', assetMetadataKey: undefined },
    aspectRatio: { message: 'Aspect ratio', assetMetadataKey: 'aspectRatio' },
    customAspectRatio: { message: 'Custom aspect ratio', assetMetadataKey: 'customAspectRatio' },
    channelLayout: { message: 'Channel layout', assetMetadataKey: undefined },
    audioRequired: { message: 'Audio required', assetMetadataKey: undefined },
    compression: { message: 'Compression', assetMetadataKey: undefined },
    audioBitRate: { message: 'Audio bit rate', assetMetadataKey: undefined },
    audioChannelLayout: { message: 'Audio channel layout', assetMetadataKey: undefined },
    audioCompression: { message: 'Audio compression', assetMetadataKey: undefined },
    colorMode: { message: 'Color mode', assetMetadataKey: 'colorMode' },
    codec: { message: 'Codec', assetMetadataKey: 'codec' },
    frameRate: { message: 'Frame rate', assetMetadataKey: 'frameRate' },
    sampleRate: { message: 'Sample rate', assetMetadataKey: 'sampleRate' },
    numberOfPages: { message: 'Number of pages', assetMetadataKey: 'numberOfPages' },
    pageWidth: { message: 'Page width', assetMetadataKey: 'pageWidth' },
    pageHeight: { message: 'Page height', assetMetadataKey: 'pageHeight' },
};
