import React from 'react';
import { AlertColor } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import get from 'lodash/get';
import Alert from 'components/ui-components-v2/Alert';
import { Brick } from 'components/bricks/types/brick.type';
import Dialog from 'components/ui-components/Dialog';
import { AssetRestrictions, AssetRestrictionsKeys, Preset } from 'components/bricks/types/preset';
import { MODEL_ASSET_VALIDATION_RESULTS, MODEL_PRESETS } from 'components/bricks/constants';
import { AssetMetadata, ValidationResults } from 'components/bricks/helpers/asset.helpers';
import Translation from 'components/data/Translation';
import { convertMaxFileSizeToHumanReadable, getCreativeFromBrick, getSeverityByValidationResults, roundSeconds } from '../helpers/asset.helpers';
import { restrictionDataMap } from '../data/restriction-data-map';
import { formatSecondsToHumanReadable, getFileExtensionName } from '../../presets-dialog/utils/table-utils';
import { DefaultAssetRestrictionKeys } from '../data/default-asset-validation-results';

import '../styles/alert-dialog.scss';

interface Props {
    brick?: Brick;
    preset?: Preset;
    severity: AlertColor | undefined;
    defaultAssetRestrictionKeys?: DefaultAssetRestrictionKeys;
    onClose: () => void;
}

export interface AssetValidationResult {
    message: string;
    severity: AlertColor;
    restriction: keyof AssetRestrictions;
    targetValue?: number | string | string[] | boolean;
    restrictedValue?: number | string | string[] | boolean;
    recommendedValue?: number | string | string[] | boolean;
    value?: any;
    unit?: 'px' | 'sec';
}

/** Restriction alert dialog component. */
const RestrictionAlertDialog = ({ brick, onClose, preset, defaultAssetRestrictionKeys }: Props) => {
    const presets: Preset[] | undefined = get(brick, MODEL_PRESETS);
    const currentPreset = preset ? preset : presets?.[0]; // Get the current preset from the brick or the provided preset.

    /**
     * Sort the asset validation results by severity.
     * Error > Warning > Info > Success.
     * @param assetValidationResults The asset validation results to sort.
     * @returns The sorted asset validation results.
     */
    const sortValidationResultsBySeverity = (assetValidationResults: AssetValidationResult[]) => {
        const severityOrder = { error: 1, warning: 2, info: 3, success: 4 };

        return assetValidationResults.sort((a, b) => severityOrder[a.severity] - severityOrder[b.severity]);
    };

    /**
     * Add the severity to the asset validation results based on the provided validation results.
     * @param assetValidationResults The asset validation results to add the severity to.
     * @param validationResults Contains the list of errors and warnings.
     * @returns The asset validation results with the severity added.
     */
    const updateValidationResultsSeverity = (assetValidationResults: AssetValidationResult[], validationResults?: ValidationResults) => {
        const resultsWithSeverity = assetValidationResults.map((result) => {
            const severity = getSeverityByValidationResults(result.restriction, result.value, validationResults);
            return { ...result, severity: severity };
        });

        return sortValidationResultsBySeverity(resultsWithSeverity);
    };

    /**
     * Get the asset validation results from the activeItem. If there are no results, it will return the restrictions
     */
    const getAssetValidationResults = () => {
        const validationResults: ValidationResults | undefined = get(brick, MODEL_ASSET_VALIDATION_RESULTS);
        const creative = getCreativeFromBrick(brick);
        const assetMetadata = creative?.data;
        const fileType = assetMetadata?.fileType;

        if (!currentPreset) {
            if (!defaultAssetRestrictionKeys || !fileType || !assetMetadata) return []; // If there are no default presets, return an empty array.
            const defaultRestrictionKeys = defaultAssetRestrictionKeys[fileType];

            return createDefaultValidationResults(defaultRestrictionKeys, assetMetadata);
        }

        const { restrictions, recommendations } = currentPreset;

        return getAssetRestrictions(validationResults, restrictions, recommendations); // Get the asset validation results based on the restrictions and recommendations.
    };

    /**
     * Create default validation results when the asset is not briefed.
     * @param restrictionKeys The restriction keys to create the default validation results for.
     * @param assetMetadata The asset metadata to get the values from.
     * @returns The default validation results.
     */
    const createDefaultValidationResults = (restrictionKeys: AssetRestrictionsKeys[], assetMetadata: AssetMetadata) => {
        return restrictionKeys.reduce<AssetValidationResult[]>((results, restrictionKey) => {
            const restrictionData = restrictionDataMap[restrictionKey];
            if (restrictionData) {
                const { message, assetMetadataKey, unit } = restrictionData;
                const value = assetMetadataKey && assetMetadata?.[assetMetadataKey];

                results.push({
                    severity: 'info',
                    message,
                    restrictedValue: Translation.get('notBriefed', 'bricks'),
                    recommendedValue: Translation.get('notBriefed', 'bricks'),
                    restriction: restrictionKey,
                    value,
                    unit
                });
            }
            return results;
        }, []);
    };

    /**
     * Get the restrictions from the preset of the asset
     * @param restrictions The restrictions in the preset of the asset
     * @param recommendations The recommendations in the preset of the asset
     */
    const getAssetRestrictions = (validationResults?: ValidationResults, restrictions?: AssetRestrictions, recommendations?: AssetRestrictions) => {
        let results: AssetValidationResult[] = [];
        const creative = getCreativeFromBrick(brick);
        const assetMetadata = creative?.data;

        if (restrictions) {
            (Object.keys(restrictions) as (keyof AssetRestrictions)[]).forEach((restriction) => {
                if (restrictionDataMap[restriction]) {
                    const { message, assetMetadataKey, unit } = restrictionDataMap[restriction];
                    const value = assetMetadataKey && assetMetadata?.[assetMetadataKey];

                    results.push({
                        severity: 'info',
                        message,
                        restrictedValue: restrictions[restriction],
                        restriction,
                        value,
                        unit
                    });
                }
            });
        }

        if (recommendations) {
            (Object.keys(recommendations) as (keyof AssetRestrictions)[]).forEach((recommendation) => {
                const optionExists = results.find((option) => option.restriction === recommendation);
                if (restrictionDataMap[recommendation]) {
                    const { message, assetMetadataKey, unit } = restrictionDataMap[recommendation];
                    const value = assetMetadataKey && assetMetadata?.[assetMetadataKey];

                    if (optionExists) {
                        optionExists.recommendedValue = recommendations[recommendation];
                    } else {
                        results.push({
                            severity: 'info',
                            message,
                            recommendedValue: recommendations[recommendation],
                            restriction: recommendation,
                            value,
                            unit
                        });
                    }
                }
            });
        }

        results = results.filter((result) => !(!result.value && !!creative)); // Filter out the results where creative is present but the value is not.

        return updateValidationResultsSeverity(results, validationResults); // Update the severity of the results based on the validation results.
    };

    /**
     * Gets the text for the restrictions cell based on the cell type.
     * @param result Asset validation result.
     * @param cellType Cell type to get the text for, it could be 'required' or 'warning'.
     */
    const getRestrictionsCellText = (result: AssetValidationResult, cellType: 'required' | 'warning') => {
        const { restriction, unit = '', restrictedValue = '', recommendedValue = '', targetValue } = result;
        const textValue = targetValue ?? (cellType === 'required' ? restrictedValue : recommendedValue); // Get the text value based on the cell type.

        if (!textValue) return ''; // If there is no text value, return an empty string.

        // Make sure the human readable size is used for the max file size.
        if (restriction === 'maxSizeKb' && typeof textValue === 'number') {
            return convertMaxFileSizeToHumanReadable(textValue); // Convert the max file size to human readable.
        }

        if (restriction === 'fileExtension' && Array.isArray(textValue)) {
            return getFileExtensionName(textValue as any); // Get the file extension name with proper formatting.
        }

        if (unit === 'sec' && typeof textValue === 'number') {
            return formatSecondsToHumanReadable(textValue);
        }

        if (!currentPreset) return textValue; // If there is no preset, return only the text value.
        return `${textValue}${unit}`; // Return the recommended value with the unit.
    };

    /**
     * Gets the text for the asset cell.
     * @param result Asset validation result.
     */
    const getAssetCellText = (result: AssetValidationResult) => {
        const { value = '', unit = '' } = result;
        let trimmedValue: number | string = value.toString().replaceAll(' ', ''); // Remove all spaces from the value.

        if (!trimmedValue) return ''; // If there is no value, return an empty string.

        if (unit === 'sec' && typeof value === 'number') {
            trimmedValue = roundSeconds(value); // Round the seconds to 2 decimal places.
        }

        return `${trimmedValue}${unit}`; // Return the recommended value with the unit.
    };

    return (
        <Dialog open title={Translation.get('labels.requirements', 'common')} onClose={onClose}>
            <div className="bricks-creative-input-alert-dialog">
                {currentPreset && (
                    <Alert severity="info" className="bricks-creative-input-alert-dialog__alert">
                        <div className="bricks-creative-input-alert-dialog__alert__copy">
                            <span className="bricks-creative-input-alert-dialog__alert__copy__title">{currentPreset.title}</span>
                            <span className="bricks-creative-input-alert-dialog__alert__copy__description">{currentPreset.description}</span>
                        </div>
                    </Alert>
                )}
                {!currentPreset && defaultAssetRestrictionKeys && (
                    <Alert severity="info" className="bricks-creative-input-alert-dialog__alert">
                        <div className="bricks-creative-input-alert-dialog__alert__copy">
                            <span className="bricks-creative-input-alert-dialog__alert__copy__title">{Translation.get('assetNotBriefed', 'bricks')}</span>
                            <span className="bricks-creative-input-alert-dialog__alert__copy__description">
                                {Translation.get('notBriefingDescription', 'bricks')}
                            </span>
                        </div>
                    </Alert>
                )}
                <Table size="small" className="bricks-creative-input-alert-dialog__table">
                    <TableHead>
                        <TableRow>
                            <TableCell className="bricks-creative-input-alert-dialog__table__head-cell" align="left"></TableCell>
                            <TableCell className="bricks-creative-input-alert-dialog__table__head-cell">{Translation.get('labels.head', 'common')}</TableCell>
                            <TableCell className="bricks-creative-input-alert-dialog__table__head-cell-center">
                                {Translation.get('labels.required', 'common')}
                            </TableCell>
                            <TableCell className="bricks-creative-input-alert-dialog__table__head-cell-center">
                                {Translation.get('labels.recommended', 'common')}
                            </TableCell>
                            {brick && (
                                <TableCell className="bricks-creative-input-alert-dialog__table__head-cell-center">
                                    {Translation.get('yourAsset', 'bricks')}
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getAssetValidationResults().map((result: AssetValidationResult) => (
                            <TableRow key={`${result.restriction} ${result.message}`}>
                                <TableCell align="left" style={{ paddingLeft: 8, paddingRight: 8, width: 32 }}>
                                    <Alert className="bricks-creative-input-alert-dialog__table__alert" severity={result.severity} />
                                </TableCell>
                                <TableCell>{result.message}</TableCell>
                                <TableCell className="bricks-creative-input-alert-dialog__table__cell">{getRestrictionsCellText(result, 'required')}</TableCell>
                                <TableCell className="bricks-creative-input-alert-dialog__table__cell">{getRestrictionsCellText(result, 'warning')}</TableCell>
                                {brick && (
                                    <TableCell
                                        className={`bricks-creative-input-alert-dialog__table__cell bricks-creative-input-alert-dialog__table__cell--${result.severity}`}>
                                        {getAssetCellText(result)}
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </Dialog>
    );
};

export default RestrictionAlertDialog;
