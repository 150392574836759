import { v4 as uuidv4 } from 'uuid';
import get from 'lodash/get';
import { SourceData } from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';
import {
    MODEL_CREATIVES,
    MODEL_ASSET_VALIDATION_RESULTS,
    MODEL_PRESETS,
    MODEL_UPLOAD_RESTRICTION_TYPE,
    MODEL_TITLE,
    ACCEPTED_FILE_TYPES,
    MODEL_ASSET_UPLOAD_IS_LOADING,
    MODEL_UPDATED_BRICKS
} from 'components/bricks/constants';
import AssetRestrictionsHelper from 'components/bricks/helpers/asset-restriction.helpers';
import { BrickData, Brick, FileType } from 'components/bricks/types/brick.type';
import { Preset } from 'components/bricks/types/preset';
import { CreativeV2CustomUpload } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import ComponentStore from 'components/data/ComponentStore';
import { AssetHelpers, AssetMetadata } from 'components/bricks/helpers/asset.helpers';
import Translation from 'components/data/Translation';
import BricksComponentStoreHelper from 'components/bricks/helpers/bricks-component-store.helper';
import BrickHelpers from 'components/bricks/helpers/brick.helpers';

/** Helper class for the creative input component. */
class CreativeInputHelper {
    /**
     * Check if the brick has restrictions.
     * @param brick Current brick.
     * @returns True if the brick has restrictions, false otherwise.
     */
    static hasRestrictions(brick: Brick): boolean {
        return brick?.data?.presets?.[0]?.restrictions && brick?.data?.uploadRestrictionType !== 'free';
    }

    /**
     * Get the asset type from the brick.
     * @returns The asset type from the brick.
     */
    static getAssetType(brick: Brick, assetType?: FileType): FileType | FileType[] {
        if (assetType) return assetType; // If the asset type is provided, return it.

        const presets: Preset[] | undefined = get(brick, MODEL_PRESETS); // Get the presets from the brick.
        const restrictionType: BrickData['uploadRestrictionType'] | undefined = get(brick, MODEL_UPLOAD_RESTRICTION_TYPE); // Get the restriction type from the brick.

        if (!presets || !presets.length || restrictionType === 'free') return ACCEPTED_FILE_TYPES; // If there are no presets or the restriction type is free, return all the accepted file types.

        return presets[0].assetType; // Return the asset type from the presets.
    }

    /**
     * Handles the loading state of the asset upload.
     * @param isLoading The loading state of the asset upload.
     */
    static handleLoadingState = (isLoading: boolean): void => {
        ComponentStore.setModel('Bricks', MODEL_ASSET_UPLOAD_IS_LOADING, isLoading);
    };

    /**
     * Handles the asset mutation after the asset is uploaded.
     * @param brick Current brick.
     * @param assetData The uploaded asset data.
     * @param dataType The data type of the asset.
     */
    static async handleAssetMutation(brick: Brick, assetData: SourceData, dataType?: string): Promise<void | undefined> {
        try {
            this.handleLoadingState(true); // Set the loading state to true.

            if (dataType === 'sourceData') return; // If the data type is source data, return.
            if (!this.isAssetValid(assetData)) throw new Error(); // Make sure the asset has an url else throw an error.

            // If the asset is removed, clear the asset data from the brick.
            if (this.isAssetRemoved(assetData)) {
                this.clearCreativeDataAndResults(brick);
                return;
            }

            const assetMetadata = await AssetHelpers.getAssetMetadataBySourceData(assetData); // Get the asset metadata.
            if (!assetMetadata) throw new Error(); // Throw error if there is no asset metadata.

            // Only validate the asset if the brick has a preset and the upload restriction type is restricted.
            if (this.shouldValidateAsset(brick)) {
                this.validateAsset(brick, assetMetadata);
            }

            this.updateBrickCreative(brick, assetData, assetMetadata); // Update the brick with the new asset and metadata.
        } catch (error) {
            SnackbarUtils.error(Translation.get('feedback.errors.oops', 'common'));
        } finally {
            this.handleLoadingState(false);
        }
    }

    /**
     * Check if the asset is removed.
     * @param assetData The asset data to check.
     * @returns True if the asset is removed, false otherwise.
     */
    private static isAssetRemoved(assetData: SourceData) {
        return typeof assetData === 'object' && Object.keys(assetData).length === 0;
    }

    /**
     * Clear the creative data and validation results from the brick.
     * @param brick Current brick.
     */
    private static clearCreativeDataAndResults(brick: Brick) {
        const brickPrefix = BrickHelpers.getBrickPrefix(brick.id);

        ComponentStore.setMultiModels('Bricks', [
            [`${brickPrefix}.${MODEL_CREATIVES}`, []],
            [`${brickPrefix}.${MODEL_ASSET_VALIDATION_RESULTS}`, null]
        ]);
    }

    /**
     * Check if the asset should be validated or not.
     * @param brick Current brick.
     * @returns True if the asset should be validated, false otherwise.
     */
    private static shouldValidateAsset(brick: Brick) {
        const presets: Preset[] | undefined = get(brick, MODEL_PRESETS);
        const uploadRestrictionType: BrickData['uploadRestrictionType'] = get(brick, MODEL_UPLOAD_RESTRICTION_TYPE);
        if (uploadRestrictionType === 'free') return false;
        return presets && presets.length > 0;
    }

    /**
     * Validate the asset metadata based on the preset restrictions and recommendations.
     * @param brick Current brick.
     * @param assetMetadata The asset metadata to validate.
     */
    private static validateAsset(brick: Brick, assetMetadata: AssetMetadata) {
        const presets: Preset[] | undefined = get(brick, MODEL_PRESETS);
        const { restrictions, recommendations } = presets?.[0] ?? {};
        const restrictionsFeedback = AssetRestrictionsHelper.validateAssetMetadataByRestrictions(assetMetadata, restrictions, recommendations);

        BricksComponentStoreHelper.setBrickModel(brick.id, MODEL_ASSET_VALIDATION_RESULTS, restrictionsFeedback);
    }

    /**
     * Check if the asset is valid.
     * @param assetData The asset data to check.
     * @returns True if the asset is valid, false otherwise.
     */
    private static isAssetValid(assetData: SourceData) {
        return assetData && Object.keys(assetData).length > 0 && assetData?.url;
    }

    /**
     * Update the brick with the new uploaded asset and metadata.
     * @param brick Current brick.
     * @param assetData The uploaded asset data.
     * @param assetMetadata The asset metadata.
     */
    private static updateBrickCreative(brick: Brick, assetData: SourceData, assetMetadata: AssetMetadata) {
        const brickPrefix = BrickHelpers.getBrickPrefix(brick.id);

        const creative: CreativeV2CustomUpload = {
            id: uuidv4(),
            type: 'customUpload',
            title: assetData.title ?? '',
            data: assetMetadata
        };

        ComponentStore.setMultiModels('Bricks', [
            [`${brickPrefix}.${MODEL_CREATIVES}`, [creative]],
            [`${brickPrefix}.${MODEL_TITLE}`, assetData.title],
            [`${MODEL_UPDATED_BRICKS}.${brick.id}`, brick.id]
        ]);
    }
}

export default CreativeInputHelper;
